import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Field from '../common/Field';
import { useTranslation } from 'react-i18next';

const ResetPassword = ({ email, onSubmit, goBack }) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({ email });
  const handleChange = (e) => {
    setFormValues(() => ({
      ...formValues,
      [e.target.name]: e.target.value,
    }));
  };

  const fields = [
    { label: t('form.email'), name: 'email', type: 'email', required: true },
  ];

  const submit = () => {
    onSubmit(formValues.email);
  };

  return (
    <div className="mt-6">
      <p className="text-center">{t('resetPassword.description')}</p>
      {fields.map((field) => (
        <Field
          key={field.name}
          {...field}
          value={formValues[field.name]}
          onChange={handleChange}
        />
      ))}

      <button
        onClick={submit}
        className="w-full flex mt-4 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {t('resetPassword.submit.title')}
      </button>
      <button
        onClick={goBack}
        className="w-full flex my-4 justify-center py-2 px-4 rounded-md text-sm font-medium text-indigo-500 border-indigo-500"
      >
        {t('goBack')}
      </button>
    </div>
  );
};

ResetPassword.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
};

export default ResetPassword;
