import { getDatabase, ref, runTransaction } from 'firebase/database';
import { getCartResume } from './../helpers';
import keyBy from 'lodash/keyBy';

const getOrderNumber = async (storeId) => {
  const db = getDatabase();
  const orderRef = ref(db, 'stores/' + storeId + '/order_count');
  try {
    const result = await runTransaction(orderRef, function (data) {
      if (data !== null) {
        data = data + 1;
        return data;
      } else {
        return 1;
      }
    });

    return result.snapshot.val();
  } catch (e) {
    console.error(e);
  }
};

const getDeliveryPayload = (delivery) => {
  if (delivery.type === 'pickup') {
    return { delivery: { type: delivery.type } };
  } else {
    return { delivery };
  }
};

const getStatusPayload = (status) => {
  const createdAt = new Date().getTime();
  return {
    status,
    timeline: [
      {
        status,
        createdAt,
      },
    ],
    createdAt,
  };
};
const getStorePayload = (store) => {
  return {
    storeId: store.id,
    store: {
      name: store.name,
      logo: store.logo || null,
      uniqueName: store.uniqueName || null,
    },
  };
};
const getItemsPayload = (cartItems) => {
  const { total } = getCartResume(cartItems);
  const items = keyBy(cartItems, 'id');

  return {
    items,
    discount: 0,
    subtotal: total,
    total,
  };
};

const getLabelPayload = async (storeId) => {
  const number = await getOrderNumber(storeId);

  return {
    number,
    name: `#c-${number}`,
  };
};

const getCustomerPayload = (customer) => {
  return {
    customer: {
      name: customer.name,
      email: customer.email,
      ...(customer.phone ? { phone: customer.phone } : {}),
    },
  };
};

const getPaymentPayload = (payment) => {
  return {
    paymentMethod: payment.paymentMethod,
  };
};

const getNotesPayload = (notes) => {
  return notes?.length > 0 ? { notes } : {};
};
const getOrderPayload = async (
  items,
  customer,
  delivery,
  payment,
  store,
  user,
  notes,
) => {
  const statusPayload = getStatusPayload('pending');
  const storePayload = getStorePayload(store);
  const customerPayload = getCustomerPayload(customer);
  const deliveryPayload = getDeliveryPayload(delivery);
  const paymentPayload = getPaymentPayload(payment);
  const itemsPayload = getItemsPayload(items);
  const labelPayload = await getLabelPayload(store.id);
  const notesPayload = await getNotesPayload(notes);
  const locationId = store?.catalog?.locationId;

  return {
    ...labelPayload,
    ...customerPayload,
    ...deliveryPayload,
    ...paymentPayload,
    ...itemsPayload,
    ...storePayload,
    ...statusPayload,
    ...notesPayload,
    uid: user ? user.id : null,
    user: {
      name: user ? user.name : '',
      thumbnail: null,
    },
    ...(locationId && { locationId }),
  };
};

export { getOrderNumber, getOrderPayload };
