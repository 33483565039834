import '../styles/globals.css';
import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PropTypes from 'prop-types';
import { datadogRum } from '@datadog/browser-rum';
import Head from 'next/head';

import { CheckoutProvider } from '../hooks/useCheckout';
import { AuthProvider } from '../components/Auth';
import resources from './../locale';
import clientCredentials from './../credentials';
import { initializeApp } from 'firebase/app';
import { ModalProvider } from '../components/Modal';
import AnalyticsProvider from '../providers/AnalyticsProvider';
import { GA_TRACKING_ID } from './../gtag';

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',

  interpolation: {
    escapeValue: false,
  },
});

initializeApp(clientCredentials);

datadogRum.init({
  env: process.env.NEXT_PUBLIC_ENV,
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  site: 'us5.datadoghq.com',
  service: 'posy-catalog',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Catálogo Posy</title>
        <meta
          name="description"
          content="Catálogo en linea para hacer pedidos"
        />
        <meta property="og:title" content="Catálogo Posy" key="title" />
        <meta property="og:site_name" content="Catálogo Posy" />

        <meta
          name="og:description"
          content="Catálogo en linea para hacer pedidos"
        />
        <meta property="og:image" content="/api/og" />
        <meta property="og:image:type" content="image/jpeg" />

        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </Head>
      <AnalyticsProvider>
        <AuthProvider>
          <CheckoutProvider>
            <ModalProvider>
              <Component {...pageProps} />
            </ModalProvider>
          </CheckoutProvider>
        </AuthProvider>
      </AnalyticsProvider>
    </>
  );
}

App.propTypes = {
  Component: PropTypes.object,
  pageProps: PropTypes.object,
};

export default App;
