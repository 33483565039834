/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Select = React.forwardRef(
  ({ label, name, onChange, options, defaultValue }, ref) => {
    return (
      <>
        <div className="sm:col-span-3">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <div className="mt-1">
            <select
              id={name}
              ref={ref}
              name={name}
              autoComplete="country-name"
              onChange={(e) => {
                const { name, value } = e.target;
                onChange({ target: { name, value } });
              }}
              className="h-12 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value={null} selected="selected">
                Selecciona una opción
              </option>

              {options.map((option) => (
                <option
                  key={option.id}
                  id={option.id}
                  selected={defaultValue === option.id}
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </>
    );
  },
);

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  setSelected: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default Select;
