import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

// eslint-disable-next-line react/display-name
const Field = React.forwardRef(
  (
    {
      label,
      name,
      type,
      value,
      onChange,
      disabled = false,
      errorMessage,
      options,
      onBlur,
      onFocus,
    },
    ref,
  ) => {
    return (
      <div className="mt-6">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-2 relative rounded-md shadow-sm">
          {type === 'select' ? (
            <Select
              ref={ref}
              key={name}
              name={name}
              options={options}
              defaultValue={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          ) : (
            <input
              ref={ref}
              type={type}
              id={name}
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              onBlur={onBlur}
              onFocus={onFocus}
              className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-12 p-4 disabled:bg-gray-100"
            />
          )}
          {errorMessage && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errorMessage}
          </p>
        )}
      </div>
    );
  },
);

Field.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  unRegister: PropTypes.func,
};

export default Field;
