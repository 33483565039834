/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { logEvent as logTagEvent } from '../gtag';
import { getUniqueNameFromUrl } from '../components/helpers';
import useStore from '../hooks/useStore';

const ModalContext = React.createContext(null);

const AnalyticsProvider = ({ children }) => {
  const { store } = useStore();
  useEffect(() => {
    const analyticsId = process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_ID;
    analyticsId && amplitude.init(analyticsId);
  }, []);

  const logEvent = ({ action }) => {
    const storeUniqueName = getUniqueNameFromUrl();
    const baseParams = {
      storeUniqueName,
      storeId: store?.id,
      storeName: store?.name,
      storeCity: store?.city,
      storeState: store?.state,
      storeCountry: store?.country,
      storeAllowOnlineOrders: store?.catalog?.allowOnlineOrders,
      storeAllowShipping: store?.catalog?.allowShipping,
      showOutOfStockItems: store?.catalog?.showOutOfStockItems,
      catalogVersion: store?.catalogVersion,
    };

    amplitude.track(action, baseParams);
    logTagEvent({ action });
  };

  const logUser = (user) => {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('userName', user?.name);
    amplitude.identify(identifyEvent);
    amplitude.setUserId(user.id);
  };

  const value = { logEvent, logUser };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

function useAnalytics() {
  return React.useContext(ModalContext);
}

export { useAnalytics };
export default AnalyticsProvider;
