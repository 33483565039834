import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const usePaymentOrder = (paymentOrderId) => {
  const [paymentOrder, setPaymentOrder] = useState();

  useEffect(() => {
    if (paymentOrderId) {
      getPaymentOrder(paymentOrderId);
    }
  }, []);

  const getPaymentOrder = async () => {
    const paymentOrderRef = doc(
      getFirestore(),
      'paymentOrders',
      paymentOrderId,
    );
    const docSnap = await getDoc(paymentOrderRef);

    if (docSnap.exists()) {
      const paymentOrder = docSnap.data();

      setPaymentOrder({ id: docSnap.id, ...paymentOrder });
    } else {
      console.error('No such document!');
    }
  };

  return paymentOrder;
};

export default usePaymentOrder;
