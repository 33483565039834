import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useAnalytics } from '../../providers/AnalyticsProvider';
import AuthContext from './AuthContext';
import { useModal } from '../Modal';
import Auth from './AuthSimple';

function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [isUserLoading, setUserLoading] = useState(true);
  const { logEvent, logUser } = useAnalytics();
  const { openModal } = useModal();

  const openAuthModal = () => {
    sessionStorage.setItem('previousPath', location.pathname);
    openModal('Auth screen', <Auth />);
  };

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      setUserLoading(true);

      if (user) {
        const db = getFirestore();
        const ref = doc(db, 'users', user.uid);
        try {
          const userSnapshot = await getDoc(ref);
          if (userSnapshot.exists) {
            const userData = { id: userSnapshot.id, ...userSnapshot.data() };
            setUser(userData);
            logUser(userData);
          }
        } catch (e) {
          //
        } finally {
          setUserLoading(false);
        }
      } else {
        setUserLoading(false);
        setUser(null);
      }
    });
  }, []);

  const logout = () => {
    const auth = getAuth();
    logEvent({ action: 'sign_out' });
    signOut(auth).catch((error) => {
      // An error happened.
      alert('error happened', error);
    });
  };

  return (
    <AuthContext.Provider
      value={{ user, setUser, isUserLoading, signOut: logout, openAuthModal }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.children,
};

export default AuthProvider;
