const colors = {
  redBold: '#991b1b',
  redText: '#b91c1c',
  greenBold: '#166534',
  greenText: '#15803d',
  redBg: '#fef2f2',
  greenBg: '#f0fdf4',
};

export { colors };
