import {
  getFirestore,
  collection,
  doc,
  addDoc,
  setDoc,
} from 'firebase/firestore';

import { useAnalytics } from '../providers/AnalyticsProvider';
import { getOrderPayload } from './../components/checkout/helpers';
import { useAuth } from '../components/Auth';

const useOrderRequest = (reset) => {
  const { user } = useAuth();
  const { logEvent } = useAnalytics();

  const createOrder = async (checkout, store) => {
    const orderPayload = await getOrderPayload(
      checkout.cartItems,
      { name: checkout.name, phone: checkout.phone, email: checkout.email },
      checkout.delivery,
      { paymentMethod: checkout.paymentMethod },
      store,
      user,
      checkout.notes,
    );
    const db = getFirestore();

    try {
      await addDoc(collection(db, 'orders'), orderPayload);
      reset();
      logEvent({ action: 'create_order', value: 1 });
    } catch (e) {
      //
      console.warn(e);
    }
  };

  const requestPayment = async (checkout, store) => {
    const orderPayload = await getOrderPayload(
      checkout.cartItems,
      { name: checkout.name, phone: checkout.phone, email: checkout.email },
      checkout.delivery,
      { paymentMethod: checkout.paymentMethod },
      store,
      user,
      checkout.notes,
    );
    const db = getFirestore();
    const checkoutRef = doc(collection(db, 'paymentOrders'));
    await setDoc(checkoutRef, orderPayload);
    const payload = {
      amount: orderPayload.total.toString(),
      currency: 'CLP',
      email: orderPayload.customer.email,
      reference: checkoutRef.id,
      storeId: orderPayload.storeId,
    };
    const response = await fetch(`/api/payment/request`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    const { redirectUrl } = await response.json();
    window.location.replace(redirectUrl);

    return checkoutRef.id;
  };

  return { createOrder, requestPayment };
};

export default useOrderRequest;
