import { useEffect } from 'react';
import { priceFormatter } from '@posy/helpers';
import useSWR from 'swr/immutable';

import { getUniqueNameFromUrl } from '../components/helpers';

const { setCurrency } = priceFormatter;

const useStore = () => {
  const uniqueName = getUniqueNameFromUrl();
  const { data: store, error } = useSWR(`/api/store/${uniqueName}`, fetcher);

  useEffect(() => {
    setCurrency(store?.currency);
  }, [uniqueName]);

  return {
    store,
    isLoading: !error && !store,
    isError: error,
  };
};

const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default useStore;
