import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalContext from './ModalContext';
import Modal from './Modal';
import useModalControl from './useModalControl';
import { useRouter } from 'next/router';

const ModalProvider = ({ children }) => {
  const router = useRouter();
  const { modalState, openModal, closeModal } = useModalControl({});

  useEffect(() => {
    if (modalState.isOpen) {
      closeModal();
    }
  }, [router.pathname]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal {...modalState} onClose={closeModal} />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.func,
};

export default ModalProvider;
