import React, { useState, useEffect } from 'react';
import useCart from './useCart';
import useLocalStorage from './useLocalStorage';
import useOrderRequest from './useRequest';
import usePaymentOrder from './usePaymentOrder';
import { useRouter } from 'next/router';
import { keyBy } from 'lodash';

const CheckoutContext = React.createContext();

const initialValues = {
  email: null,
  name: null,
  phone: null,
  delivery: null,
  cartItems: [],
  step: 0,
};

// eslint-disable-next-line react/prop-types
function CheckoutProvider({ children }) {
  const { value, setValue } = useLocalStorage('checkout', initialValues);
  const {
    cartItems,
    addItem,
    editItemQuantity,
    removeItem,
    resetCartItems,
    markAsOutOfStock,
    markItemsAsUnavailable,
    markItemsAsAvailable,
  } = useCart(value.cartItems);
  const [isLoading, setIsLoading] = useState();
  const lastPaymentOrder = usePaymentOrder(value?.paymentOrderId);
  const router = useRouter();
  const [availabilityIndex, setAvailabilityIndex] = useState({});

  useEffect(() => {
    if (lastPaymentOrder?.status) {
      setPaymentOrderStatus(lastPaymentOrder?.status);
    }
  }, [lastPaymentOrder?.status]);

  useEffect(() => {
    setValue((state) => {
      return { ...state, cartItems };
    });
  }, [cartItems]);

  const setContact = (contact) => {
    setValue((value) => ({ ...value, ...contact, step: 2 }));
  };

  const setDelivery = (delivery) => {
    setValue((value) => ({ ...value, ...delivery, step: 3 }));
  };

  const setPayment = (payment) => {
    setValue((value) => ({ ...value, ...payment, step: 4 }));
  };

  const setInstructions = (notes) => {
    setValue((value) => ({ ...value, notes }));
  };

  const setPaymentOrderId = (paymentOrderId) => {
    setValue((value) => ({ ...value, paymentOrderId: paymentOrderId }));
  };

  const setPaymentOrderStatus = (paymentOrderStatus) => {
    setValue((value) => ({ ...value, paymentOrderStatus }));
  };

  const reset = () => {
    setValue(initialValues);
    resetCartItems([]);
  };
  const { createOrder, requestPayment } = useOrderRequest(reset);

  const confirm = async (store, isOnlinePayment) => {
    if (isOnlinePayment) {
      const paymentOrderId = await requestPayment(value, store);
      setPaymentOrderId(paymentOrderId);
      setPaymentOrderStatus('pending');
    } else {
      createOrder(value, store);
    }
  };

  const checkInventory = async (items, locationId) => {
    const response = await fetch('/api/inventory/check', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items, locationId }),
    });

    const { outOfStockItems } = await response.json();
    markAsOutOfStock(outOfStockItems);

    return outOfStockItems.length === 0;
  };

  const checkAvailability = async () => {
    const response = await fetch('/api/availability/check', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: cartItems }),
    });

    const { unavailableItems } = await response.json();

    if (unavailableItems.length > 0) {
      markItemsAsUnavailable(unavailableItems);
      setAvailabilityIndex(keyBy(unavailableItems, 'productId'));
    } else {
      markItemsAsAvailable();
    }
    return unavailableItems.length === 0;
  };

  const goToCheckout = async () => {
    setIsLoading(true);
    const isAvailable = await checkAvailability();
    if (isAvailable) {
      router.push('/checkout');
    }
    setIsLoading(false);
  };

  const customValue = {
    isLoading,
    cartItems: value.cartItems,
    step: value.step,
    checkout: value,
    availabilityIndex,
    addItem,
    editItemQuantity,
    removeItem,
    setContact,
    setDelivery,
    setPayment,
    setInstructions,
    confirm,
    resetCart: reset,
    setPaymentOrderId,
    setPaymentOrderStatus,
    checkInventory,
    checkAvailability,
    goToCheckout,
  };

  return (
    <CheckoutContext.Provider value={customValue}>
      {children}
    </CheckoutContext.Provider>
  );
}

function useCheckout() {
  const context = React.useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }
  return context;
}

export { CheckoutProvider, useCheckout };
