import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ResetPassword from './ResetPassword';
import PreLogin from './PreLogin';
import Login from './Login';
import Register from './Register';

const SignInSimple = ({ preSignIn, signIn, signUp, resetPassword, goBack }) => {
  const [email, setEmail] = useState();
  const [page, setPage] = useState('prelogin');
  const [isReseted, setIsReseted] = useState(false);
  const [error, setError] = useState();

  const onPreLogin = async (email) => {
    const { isRegistered, isInvalid, error } = await preSignIn(email);
    const page = isRegistered ? 'login' : 'register';
    if (!isInvalid) {
      setEmail(email);
      setPage(page);
    }
    if (error) {
      const errorIndex = {
        google: 'Debes inicia sesión con tu cuenta de Google',
      };

      const description =
        errorIndex[error.code] || 'No es posible ser autenticado';

      setError({ description });
    }
  };

  const onLogin = async ({ email, password }) => {
    const { error } = await signIn({ email, password });

    if (error) {
      const errorIndex = {
        'auth/wrong-password':
          'El email o la contraseña ingresada son invalidos. Intente nuevamente',
      };

      const description =
        errorIndex[error.code] || 'No es posible ser autenticado';

      setError({ description });
    }
  };

  const onSignUp = async (formValues) => {
    const response = await signUp(formValues);

    if (response?.error) {
      const error = response.error;
      const errorIndex = {
        'auth/weak-password':
          'La contraseña ingresada debe tener al menos 6 caracteres',
      };

      const description =
        errorIndex[error.code] || 'No es posible ser autenticado';

      setError({ description });
    }
  };

  const onResetPassword = async (email) => {
    await resetPassword(email);
    setIsReseted(true);
    setPage('login');
  };

  const onChangeEmail = (email) => {
    setIsReseted(false);
    setPage('prelogin');
    setEmail(email);
  };

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      {page === 'prelogin' && (
        <PreLogin
          email={email}
          onSubmit={onPreLogin}
          goBack={goBack}
          error={error}
        />
      )}
      {page === 'login' && (
        <Login
          email={email}
          onSubmit={onLogin}
          isReseted={isReseted}
          goToReset={() => setPage('forgot')}
          setPage={setPage}
          onChangeEmail={onChangeEmail}
          goBack={() => setPage('prelogin')}
          error={error}
        />
      )}
      {page === 'register' && (
        <Register
          email={email}
          error={error}
          onSubmit={onSignUp}
          onChangeEmail={onChangeEmail}
        />
      )}
      {page === 'forgot' && (
        <ResetPassword
          email={email}
          onSubmit={onResetPassword}
          setPage={setPage}
          onChangeEmail={onChangeEmail}
          goBack={() => setPage('login')}
        />
      )}
    </div>
  );
};

SignInSimple.propTypes = {
  preSignIn: PropTypes.func,
  signIn: PropTypes.func,
  signUp: PropTypes.func,
  resetPassword: PropTypes.func,
  goBack: PropTypes.func,
};

export default SignInSimple;
