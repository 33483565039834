import React from 'react';
import PropTypes from 'prop-types';

const EmailLabel = ({ email, changeTitle, onPress }) => {
  return (
    <div className="flex flex-row items-center mt-4">
      <div>{email}</div>

      <button onClick={onPress} className="text-indigo-500 ml-4">
        {changeTitle}
      </button>
    </div>
  );
};

EmailLabel.propTypes = {
  email: PropTypes.string,
  changeTitle: PropTypes.string,
  onPress: PropTypes.func,
};

export default EmailLabel;
