import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/outline';

import { colors } from './../constants';

const Alert = ({ title, description, type }) => {
  const Icon = type === 'success' ? CheckCircleIcon : CheckCircleIcon;
  const color = type === 'success' ? 'green' : 'red';

  return (
    <div
      className={`rounded-md p-4`}
      style={{ backgroundColor: colors[`${color}Bg`] }}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            className={`h-5 w-5`}
            aria-hidden="true"
            style={{ color: colors[`${color}Text`] }}
          />
        </div>
        <div className="ml-3">
          {title && (
            <h3
              className={`text-sm font-medium`}
              style={{ color: colors[`${color}Bold`] }}
            >
              {title}
            </h3>
          )}
          <div
            className={`mt-2 text-sm`}
            style={{ color: colors[`${color}Text`] }}
          >
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default Alert;
