import { useState } from 'react';
import { useAnalytics } from '../providers/AnalyticsProvider';

const useCart = (initialItems) => {
  const [cartItems, setCartItems] = useState(initialItems || []);
  const { logEvent } = useAnalytics();

  const addItem = (item) => {
    logEvent({ action: 'add_to_cart' });
    setCartItems((items) => {
      const findIndex = items.findIndex(
        (currentItem) => currentItem.id === item.id,
      );
      const newItem =
        findIndex > -1
          ? {
              ...items[findIndex],
              quantity: items[findIndex].quantity + item.quantity,
            }
          : item;

      if (findIndex !== -1) {
        return [
          ...items.slice(0, findIndex),
          newItem,
          ...items.slice(findIndex + 1),
        ];
      } else {
        return [newItem, ...items];
      }
    });
  };

  const removeItem = (id) => {
    logEvent({ action: 'remove_from_cart' });
    setCartItems((items) => {
      const findIndex = items.findIndex((item) => item.id === id);
      return [...items.slice(0, findIndex), ...items.slice(findIndex + 1)];
    });
  };

  const editItemQuantity = (id, quantity) => {
    logEvent({ action: 'edit_cart_item_quantity' });
    setCartItems((items) => {
      const findIndex = items.findIndex((item) => item.id === id);
      return [
        ...items.slice(0, findIndex),
        {
          ...items[findIndex],
          quantity: quantity,
        },
        ...items.slice(findIndex + 1),
      ];
    });
  };

  const resetCartItems = () => {
    logEvent({ action: 'reset_cart_items' });
    setCartItems([]);
  };

  const markAsOutOfStock = (outOfStockItems) => {
    outOfStockItems.map(({ variationId, available }) => {
      setCartItems((items) => {
        const findIndex = items.findIndex((item) => item.id === variationId);
        return [
          ...items.slice(0, findIndex),
          {
            ...items[findIndex],
            isOutOfStock: true,
            available,
          },
          ...items.slice(findIndex + 1),
        ];
      });
    });
  };

  const markItemsAsUnavailable = (unavailableItems) => {
    setCartItems((items) => {
      unavailableItems.forEach(
        ({ variationId, isOutOfStock, available, isUnavailable }) => {
          const findIndex = items.findIndex((item) => item.id === variationId);

          if (findIndex > -1) {
            items = [
              ...items.slice(0, findIndex),
              {
                ...items[findIndex],
                ...(isOutOfStock && { available, isOutOfStock }),
                ...(isUnavailable && { isUnavailable }),
                isFlagged: isUnavailable || isOutOfStock,
              },
              ...items.slice(findIndex + 1),
            ];
          }
        },
      );

      return items.sort(
        (a, b) => (b.isFlagged || null) - (a.isFlagged || null),
      );
    });
  };

  const markItemsAsAvailable = () => {
    setCartItems((items) =>
      items.map((item) => ({
        ...item,
        isOutOfStock: false,
        isUnavailable: false,
      })),
    );
  };

  return {
    cartItems,
    addItem,
    editItemQuantity,
    removeItem,
    resetCartItems,
    markAsOutOfStock,
    markItemsAsUnavailable,
    markItemsAsAvailable,
  };
};

export default useCart;
