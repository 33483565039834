import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useForm, Field } from '@posy/components';
import EmailLabel from './components/EmailLabel';
import Alert from './components/Alert';

const Register = ({ email, error, onSubmit, onChangeEmail }) => {
  const { t } = useTranslation();
  const [formValues] = useState({ password: '' });
  const { registerField, handleSubmit } = useForm({
    defaultValues: formValues,
  });

  const fields = [
    {
      label: t('form.name'),
      name: 'name',
      type: 'text',
      rules: ['required'],
    },
    {
      label: t('form.phone'),
      name: 'phone',
      type: 'text',
      rules: ['required'],
    },
    {
      label: t('form.password'),
      name: 'password',
      type: 'password',
      rules: ['required'],
    },
  ];

  const submit = (formValues) => {
    onSubmit({
      email,
      ...formValues,
    });
  };

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div className="mt-8">
        {error && <Alert description={error.description} type="error" />}

        <EmailLabel
          email={email}
          onPress={() => onChangeEmail(email)}
          changeTitle={t('changeButton')}
        />
        <div className="mt-6">
          {fields.map((field) => (
            <div key={field.name} className="mt-3">
              <Field key={field.name} {...registerField(field)} />
            </div>
          ))}

          <button
            onClick={handleSubmit(submit)}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            {t('signUp')}
          </button>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  onChangeEmail: PropTypes.func,
  error: PropTypes.object,
};

export default Register;
