import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useForm, Field } from '@posy/components';
import EmailLabel from './components/EmailLabel';
import Alert from './components/Alert';

const Login = ({
  isReseted,
  error,
  onSubmit,
  email,
  goToReset,
  onChangeEmail,
}) => {
  const { t } = useTranslation();
  const [formValues] = useState({ password: '' });
  const { registerField, handleSubmit } = useForm({
    defaultValues: formValues,
  });

  const submit = (formValues) => {
    onSubmit({
      email,
      password: formValues.password,
    });
  };

  return (
    <div className="mt-6">
      <div className="mb-4">
        {isReseted && (
          <Alert
            title={t('resetPassword.success.title')}
            description={t('resetPassword.success.description')}
            type="success"
          />
        )}
        {error && <Alert description={error.description} type="error" />}
        <EmailLabel
          email={email}
          onPress={() => onChangeEmail(email)}
          changeTitle={t('changeButton')}
        />
      </div>

      <Field
        {...registerField({
          label: t('form.password'),
          name: 'password',
          type: 'password',
          rules: ['required'],
        })}
      />

      {!isReseted && (
        <div className="text-sm text-right mt-4">
          <a
            onClick={goToReset}
            className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
          >
            {t('resetPassword.button.title')}
          </a>
        </div>
      )}

      <button
        onClick={handleSubmit(submit)}
        className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {t('signInWithEmail')}
      </button>
    </div>
  );
};

Login.propTypes = {
  isReseted: PropTypes.string,
  error: PropTypes.string,
  email: PropTypes.string,
  goToReset: PropTypes.func,
  onSubmit: PropTypes.func,
  onChangeEmail: PropTypes.func,
};

export default Login;
