import { priceFormatter } from '@posy/helpers';

const { getItemGross } = priceFormatter;

const formatPriceValue = (value) => {
  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const getFormattedCount = (quantity, format) => {
  const isFractionable = format === 'fraction';

  return isFractionable ? 1 : quantity;
};

const getCartResume = (cartItems) => {
  return cartItems.reduce(
    (obj, item) => {
      const { quantity, format } = item;
      obj.total = obj.total + getItemGross(item);
      obj.count = obj.count + getFormattedCount(quantity, format);

      return obj;
    },
    { total: 0, count: 0 },
  );
};

const compareString = (key, str) => {
  const nameSanitized = key
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  return nameSanitized.includes(str.toLowerCase());
};

const getDiscountRate = (variation) => {
  const discount = (variation.price * 100) / variation.referencePrice;
  return `-${(100 - discount).toFixed(0)}%`;
};

const getUniqueNameFromUrl = (host) => {
  const getHostname = () => {
    if (host) {
      return host.split(':')[0];
    } else if (typeof window !== 'undefined') {
      return window.location.hostname;
    } else {
      return 'localhost';
    }
  };

  const [firstSegment] = getHostname().split('.');
  if (firstSegment === 'localhost') {
    return 'seforastore';
  } else {
    return firstSegment;
  }
};

export const countries = [
  { name: 'Argentina', id: 'AR' },
  { name: 'Bolivia', id: 'BO' },
  { name: 'Chile', id: 'CL' },
  { name: 'Colombia', id: 'CO' },
  { name: 'Costa Rica', id: 'CR' },
  { name: 'Ecuador', id: 'EC' },
  { name: 'El Salvador', id: 'SV' },
  { name: 'España', id: 'ES' },
  { name: 'Estados Unidos', id: 'US' },
  { name: 'Guatemala', id: 'GT' },
  { name: 'Honduras', id: 'HN' },
  { name: 'México', id: 'MX' },
  { name: 'Nicaragua', id: 'NI' },
  { name: 'Panamá', id: 'PA' },
  { name: 'Paraguay', id: 'PY' },
  { name: 'Perú', id: 'PE' },
  { name: 'República Dominicana', id: 'DO' },
  { name: 'Uruguay', id: 'UY' },
  { name: 'Venezuela', id: 'VE' },
];

export {
  formatPriceValue,
  getCartResume,
  getDiscountRate,
  compareString,
  getUniqueNameFromUrl,
};
