import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useForm, Field } from '@posy/components';
import Alert from './components/Alert';

const PreLogin = ({ email, onSubmit, goBack, error }) => {
  const { t } = useTranslation();
  const [values] = useState({ email });
  const { registerField, handleSubmit } = useForm({ defaultValues: values });

  const submit = (values) => {
    onSubmit(values.email);
  };

  return (
    <div className="mt-6">
      {error && <Alert description={error.description} type="error" />}

      <button
        onClick={goBack}
        className="w-full flex my-4 justify-center py-2 px-4 rounded-md text-sm font-medium text-indigo-500 border-indigo-500"
      >
        {t('signInWithDifferentMethod')}
      </button>
      <Field
        {...registerField({
          label: t('form.email'),
          name: 'email',
          type: 'text',
          rules: ['required', 'email'],
        })}
      />
      <button
        onClick={handleSubmit(submit)}
        className="w-full flex mt-4 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {t('singInOrCreateButton')}
      </button>
    </div>
  );
};

PreLogin.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  error: PropTypes.object,
};

export default PreLogin;
