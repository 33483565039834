import React from 'react';

const useModal = () => {
  const [title, setTitle] = React.useState();
  const [content, setContent] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [onClose, setOnClose] = React.useState(() => {});

  const openModal = (title, content, options, onClose) => {
    setVisible(true);
    setContent(content);
    setTitle(title);
    setOptions(options);
    setOnClose(() => onClose);
  };

  const closeModal = () => {
    setVisible(false);
    setContent(<></>);
    onClose && onClose();
  };

  const modalState = { isOpen: visible, title, content, options };
  return { modalState, openModal, closeModal };
};

export default useModal;
